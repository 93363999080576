import React from "react"
import { graphql } from "gatsby"
import { Page } from "../../components/elements"
import { Helmet } from "react-helmet"
import { Template } from "./Template"

export default function Main({
    data,
    data: {
        markdownRemark: {
            frontmatter: { fullName, title },
        },
    },
}) {
    return (
        <Page title={fullName} description={`${fullName}, ${title}`}>
            <Helmet>
                <style type="text/css">
                    {`
                    details * {
                        user-select: none;
                    }
                    
                    details:not(:first-child) {
                        border-top: 0;
                    }
                    
                    details {
                        border: 1px solid;
                    }
                    
                    details summary {
                        outline: none;
                        padding: 10px;
                    }
                    
                    details p {
                        padding: 10px;
                    }
                    `}
                </style>
            </Helmet>
            <Template data={data} />
        </Page>
    )
}

export const pageQuery = graphql`
    query ($slug: String!) {
        markdownRemark(frontmatter: { slug: { eq: $slug } }) {
            ...MemberFields
        }
    }
`
