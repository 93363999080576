import React from "react"
import { ListGroup, Row } from "react-bootstrap"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Components } from "../../components/elements"
import { PageCenter } from "../../components/elements/wrappers/PageCenter.jsx"
import { Markdown } from "../../components/tools/Markdown"
import { removeLocalPath } from "../../cms/previews/tools/removeLocalPath"

export function Template({
    data: {
        markdownRemark: {
            html,
            frontmatter: {
                fullName,
                title,
                featuredImage,
                components,
                resume,
                info,
            },
        },
    },
}) {
    const components_and_pdf = components?.length
        ? components.map(({ type, tab, ...props }) => {
              const pdf = {
                  title: "Download PDF",
                  publicURL: resume?.publicURL,
              }
              if (type == "tabs")
                  return { ...props, type, tab: tab?.concat(pdf) }
              return { type, tab, ...props }
          })
        : null

    const image = getImage(featuredImage)

    return (
        <PageCenter>
            <Row className="top mb-4" style={{ flexWrap: "unset" }}>
                <div className="left">
                    {!image ? (
                        <img src={removeLocalPath(featuredImage)} alt="" />
                    ) : (
                        <GatsbyImage
                            image={image}
                            alt={fullName}
                            style={{ width: "200px" }}
                            className="m-3"
                        />
                    )}
                </div>
                <div className="mx-4" style={{ maxWidth: "400px" }}>
                    <h3 style={{ color: "var(--red1)" }}>{fullName}</h3>
                    <h4 className="border-bottom pb-2 mb-4">{title}</h4>

                    <ListGroup>
                        {info?.map(({ label, value }, i) => (
                            <ListGroup.Item key={i}>
                                <span className="mr-3">{label}:</span>
                                {value}
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                </div>
            </Row>
            <div className="bottom">
                {!components?.length ? null : (
                    <Components
                        components={resume ? components_and_pdf : components}
                        tab_type="horizontal"
                    />
                )}
            </div>
            {!html ? null : (
                <>
                    <h5 className="border-bottom pb-3 mt-5">
                        More Information
                    </h5>
                    <Markdown>{html}</Markdown>
                </>
            )}
        </PageCenter>
    )
}
